import React from 'react'
import { StaticQuery, graphql } from "gatsby"

// import 'semantic-ui-less/semantic.less';
import ScrollUpButton from "react-scroll-up-button";
import styled from 'styled-components';

import './styles';

import BFBlogHeader from './BFBlogHeader';
import Footer from './Footer';
import Page from './Page';
import Meta from './Meta';

const BlogLayout = ({ children, className }) => (
  <StaticQuery
    query={graphql`
      query BlogLayoutQuery {
          site {
            siteMetadata {
              title,
              description,
            }
          }
        }
    `}
    render={data => (
      <div className={className}>
        <Meta/>
        <BFBlogHeader/>
        <Page>
          {children}
        </Page>
        <Footer />
        <ScrollUpButton />
      </div>
    )}
  />
)

export default styled(BlogLayout)`
  .bf-blog-post {
    max-width: 1127px;
    margin: auto !important;
  }
`;
