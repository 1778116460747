import React from 'react';
import { IntlProvider } from 'react-intl';
import styled, { ThemeProvider } from 'styled-components';
import 'typeface-open-sans';

// TODO: update theme
const theme = {
  sizeHighlight: '55px',
  sizeHeader: '30px',
  sizeMobileText: '16px',
  sizeText: '21px',
  sizeSubtext: '14px',
  sizeSmallText: '11px',

  // color palette
  brandPink: '#FF6250',
  primaryBlack: '#424242',
  primaryDarkGrey: '#707070',
  primaryGrey: '#AAAAAA',
  primaryLightGrey: '#E6E6E6',
  primaryTeal: '#15919B',
  primaryPink: '#CD5B49',
  primaryOrange: '#F8A055',
  primaryYellow: '#FFDB5C',
  primaryWhite: '#FFFFFF',
  altTeal: '#00949d',
  altPink: '#CD5B49',
  altOrange: '#FF9C41',
  altDarkYellow: '#FFDB39',
  altLightYellow: '#FDE05B',
  accentPurple: '#F910C7',
  accentDarkBlue: '#2103F7',
  accentLightBlue: '#11BEF4',
  accentGreen: '#94FF2E',
  accentTeal: '#03FFE0',
  altAccentPurple: '#A95666', // E05CB4
  altAccentDarkBlue: '#293850',
  altAccentLightBlue: '#74C4E1',
  altAccentGreen: '#C1FF07',
  altAccentTeal: '#BAEDB1', // 7DB587 3BB4AE
  divider: '1px solid rgba(34,36,38,.15)',
  // end color palette

  mobile: '600px',
  notMobile: '601px',
  tablet: '992px',
  maxWidth: '100%',
  bs: '0 12px 24px 0 rgba(0, 0, 0, 0.09)',

  // spacing
  p1: '20px',
  m1: '20px',

  ps1: '24px',
  ms1: '24px',
  // fontsize
};

const StyledPage = styled.div`
  background: white;
  /* max-width: 100%; */
  /* margin: 0 auto; */
  color: ${props => props.theme.primaryBlack};

  .grid {
    /* margin: 0px !important; */
  }

  &.pageContainer {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const Page = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <IntlProvider locale="en">
        <StyledPage className="page">
          {children}
        </StyledPage>
      </IntlProvider>
    </ThemeProvider>
  );
}

export default Page;
